.mention {
    position: relative;
    overflow-y: visible;
    min-height: 70px;
    border: unset;

    color: #444444;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0;
    // line-height: 20px;   removed to solve cursor issue
    .mention__highlighter {
        padding: 5px;
        .mention__highlighter__substring {
            color: #444444 !important;
            visibility: visible !important;
            font-size: 15px;
        }
    }

    .mention__input {
        border: 1px solid #dfe2e5;
        color: transparent;
        cursor:text;
        caret-color: black;
        padding: 5px;
        font-size: 15px !important;

        &:focus {
            outline: none;
            border: 1px solid #2390bb;
        }
    }

    .mention__suggestions {
        border: 1px solid #dfe2e5;

        .mention__suggestions__item {
            padding: 5px 10px;
            border-bottom: 1px solid #dfe2e5;

            &:hover {
                background-color: #dfe2e5;
            }
        }
    }
}

.mention-link {
    color: #2390bb;
}

.Mention-container {
    .Mention-textarea {
        height: 0 !important;

        &:focus {
            outline: none;
            border: 1px solid #2390bb;
        }
    }
}

.flex-row-start {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-row-center {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
}

.comments-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    .main-comment {
        @extend .flex-row-start;
        border-bottom: #dfe2e5 1px solid;
        padding-bottom: 15px;
        width: 100%;

        &:not(:nth-of-type(2)) {
            padding-top: 10px;
        }
    }

    .add-comment {
        @extend .flex-row-start;
        width: 100%;
    }

    .content {
        @extend .flex-row-start;
        width: 80%;
        padding: 20px 20px 0 65px; // 20px;

        .user-img {
            height: 32px;
            width: 32px;
            background: url("../../../assets/user-avatar.svg") no-repeat;
            margin-top: -5px;
        }

        .user-comment-box {
            display: flex;
            flex-flow: column;
            width: calc(100% - 32px);
            padding-left: 8px;

            .user-name {
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 20px;
            }

            .user-comment {
                margin-top: 15px;
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 20px;

                &.no-user {
                    margin-top: 0;
                }
            }

            .info {
                @extend .flex-row-center;
                margin-top: 20px;

                &-timeline {
                    color: #444444;
                    font-family: "Source Sans Pro";
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 15px;
                    opacity: 0.8;
                }

                &-oval {
                    height: 3px;
                    width: 3.08px;
                    background-color: #444444;
                    margin-left: 15px;
                }

                &-reply {
                    color: #2390bb;
                    font-family: "Source Sans Pro";
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;
                    margin-left: 15px;
                    cursor: pointer;
                }

                & > div:first-child {
                    margin-left: 0;
                }
            }

            .comment-box {
                margin-top: 15px;
                display: flex;
                flex-flow: column;

                textarea {
                    height: 90px;
                }

                .btn-group {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: space-between;

                    .lft,
                    .rgt {
                        display: flex;
                        flex-flow: row wrap;
                        align-items: center;
                    }

                    .cancel {
                        color: #2390bb;
                        font-family: "Source Sans Pro";
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 18px;
                        margin-left: 10px;
                        margin-top: 10px;
                        cursor: pointer;
                    }

                    .group {
                        width: unset;
                        padding-top: 10px;
                        margin-left: 10px;
                        .select {
                            width: 125px;
                        }
                        .options {
                            width: 125px;
                        }
                    }
                }

                .option-label {
                    color: #444444;
                    font-family: "Source Sans Pro Light";
                    font-size: 13px;
                    // font-weight: 300;
                    letter-spacing: 0;
                    line-height: 13px;
                    margin-top: 10px;
                }

                &.no-user {
                    margin-top: 0;
                }
            }

            .discussion {
                height: 40px;
                background-color: #f8f8f8;
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 20px;

                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: flex-start;
                padding-left: 15px;
                cursor: text;
            }

            &.comment {
                background-color: #f8f8f8;
                margin-left: 10px;
                padding-right: 15px;
                padding-left: 15px;
                padding-bottom: 15px;
                border-radius: 2px;
            }
        }

        &.comment {
            align-items: flex-start;
            padding-left: 20px;

            .user-img {
                margin-top: 2.5px;
            }
        }

        &.replies {
            width: 100%;
            padding: 20px 0;

            &:nth-of-type(4) {
                margin-top: 10px;
            }

            &.reply-on {
                &:nth-of-type(5) {
                    margin-top: 10px;
                }
            }
        }
    }
}

.child-comments-wrapper {
    width: 100%;
}

.hover-div {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    color: #2390bb;
  }
  
  .hover-text {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 5px;
  }
  
  .hover-div:hover .hover-text {
    visibility: visible;
  }

@keyframes highlight {
    0% {
        background: #2390bb;
    }
    100% {
        background: none;
    }
}
.highlight-main-comment {
    padding: 20px;
    border-radius: 8px;
   animation: highlight 5s;
}
