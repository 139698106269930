.guest-login {
    font-size: 32px;
    align-self: center;
    margin-bottom: 20px;
    color: #ffff;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.link-for-signup {
    a {
        color: #68bbdb !important;
    }
}
