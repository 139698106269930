@use "../../../core/styles/mixins";

.temp {
    margin-top: 50px; // 72px;
}

.grid-container {
    @include mixins.flexDisplay(column, flex-start, center, nowrap);
    margin-top: 50px;
    width: 100%;

    .a-left {
        height: 16px;
        width: 16px;
        background: url("../../../assets/arrow-left.svg") no-repeat;
    }

    .redirection {
        color: #2390bb;
        font-family: "Source Sans Pro";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 12px;
        margin-bottom: 20px;
        cursor: pointer;

        @include mixins.flexDisplay(row, center, flex-start, nowrap);
    }

    .grid-title {
        color: #444444;
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 20px;
        padding-left: 20px;

        &.big {
            color: #444444;
            font-family: "Source Sans Pro Light";
            font-size: 28px;
            font-weight: unset;
            letter-spacing: 0;
            line-height: 32px;
            padding: 5px 20px 0;

            &.sys-tab {
                padding: 0 20px 0 0;
                margin-bottom: 0;
            }
        }

        &.small {
            color: #444444;
            font-family: "Source Sans Pro Light";
            font-size: 15px;
            font-weight: unset;
            margin-bottom: 10px;
            letter-spacing: 0;
            line-height: 32px;
            padding: 5px 20px 0;

            span {
                color: #2390bb;
                cursor: pointer;
            }

            &.sys-tab {
                padding: 0 20px 0 0;
                margin-bottom: 0;
            }
        }

        &.large {
            color: #444444;
            font-family: "Source Sans Pro Light";
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
            padding: 5px 20px 0;
        }
    }

    .title-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        padding: 20px 0 20px 0;

        .omi-btn {
            font-family: "Source Sans Pro Light";
            font-weight: unset;
        }

        &.gene-set {
            width: 100%;
            justify-content: space-between;

            .omi-btn {
                width: 169px;
                .add {
                    width: 16px;
                }
                &-txt {
                    margin-left: unset;
                }
            }
        }

        .grid-search-container {
            height: 28px;
            width: 355px; // 350px;
            padding: 15px;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;

            .search-icon {
                height: 16px;
                width: 16px;
                background: url("../../../assets/list-search-icon.svg") no-repeat;
                position: absolute;
                left: 25px;
                cursor: pointer;
            }

            .form-input {
                height: 28px;
                border: 1px solid #dfe2e5;
                border-radius: 16px 16px;
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                min-width: unset;
                // width: 100%;
                width: 190px;
                padding-left: 32px;
                // padding-right: 32px;
            }

            .adv-search-icon {
                height: 16px;
                width: 16px;
                background: url("../../../assets/adv-search.png") no-repeat;
                position: absolute;
                right: -5px;
                cursor: pointer;
            }

            .adv-search {
                font-family: "Source Sans Pro";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 20px;
                cursor: pointer;
                // width: inherit;
                width: auto;
                margin-left: 5px;
                color: #2390bb;
            }
        }
    }

    &.files {
        // justify-content: flex-start; // space-between;
        // align-items: center;
        margin-top: unset;
        margin-bottom: 20px;
        // height: 100%; // 95%;

        display: flex;
        flex-flow: column;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-start;

        // position: relative;

        &.recent-files,
        &.ds-files {
            align-items: flex-start;
        }
    }

    &.first {
        margin-top: 20px;
    }

    &.analyte-mapping {
        margin-top: 30px;
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        align-items: flex-start;
        justify-content: flex-start;

        .grid-title {
            margin-bottom: 35px;
        }

        .redirection {
            padding-left: 15px;
        }
    }

    &.samples {
        height: 100%;
        position: relative;
        margin-top: 25px;
        @include mixins.flexDisplay(column, flex-start, flex-start);
    }
}

.search-container {
    // width: fit-content; // 45%; // 100%;
    position: absolute;
    padding: 0 20px;
    top: 192px; // 175px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    z-index: 3;

    .border-container {
        background: #f7f7f7;
        border: 1.5px solid #dfe2e5;
        border-radius: 5px;
    }

    &-content {
        // background: #e4edf1;
        // border-radius: 5px 5px 0 0;
        padding: 15px 15px 15px 35px;
        width: 100%;
        height: fit-content;

        display: flex;
        flex-flow: row wrap;
        align-items: flex-start; // center;
        justify-content: flex-start; // center; // flex-start;

        .group {
            width: auto;
            margin-right: 30px;

            // margin-bottom: 10px;
            // &:not(:last-child) {
            // }

            .e-multiselect {
                width: 270px !important;
                height: 35px;
                border-radius: 2px;
                border: 1px solid #dfe2e5 !important;

                &:focus {
                    border-radius: 2px;
                    border: 1px solid #2390bb;
                }
            }
        }

        &:not(:first-child) {
            padding-top: 0;
        }

        &:first-child {
            padding-top: 30px;
        }
    }

    &-button {
        // background: #e4edf1;
        // border-radius: 0 0 5px 5px;

        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 30px;

        .omi-btn {
            margin-right: 10px;
        }
    }

    &.fadeOutUp {
        display: none;
    }

    &.animate__fadeInDown {
        animation-duration: 0.2s;
    }

    &.animate__fadeOutUp {
        animation-duration: 0.2s;
    }
}

.spacer {
    height: 100px;

    &.half {
        height: 50px;
    }
}

.notification__item--info {
    background-color: #2390bb !important;
    border-left: 8px solid #196a8a !important;
}

// NETWORK COMPONENT STYLES -----------------------------------------

.scale-up-br {
    -webkit-animation: scale-up-br 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-br 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-br {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}
@keyframes scale-up-br {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

.scale-down-br {
    -webkit-animation: scale-down-br 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-down-br 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-down-br {
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
    0% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}
@keyframes scale-down-br {
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
    0% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

.control-bar {
    position: absolute;
    top: -12px; // 0;
    height: 36px;
    width: 100%;
    background-color: #f8f8f8;
    padding: 0 20px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    z-index: 2;
    justify-content: space-between;

    .zoom {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 150px;

        .in {
            background: url("../../../assets/zoom-in.svg") no-repeat;
            height: 24px;
            width: 30px; // 24px;
            cursor: pointer;

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        .out {
            margin-left: 5px;
            background: url("../../../assets/zoom-out.svg") no-repeat;
            height: 24px;
            width: 30px; // 24px;
            cursor: pointer;

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        .reset-icon {
            margin-left: 5px;
            height: 16px;
            width: 16px;
            background: url("../../../assets/reset-512_16x16.png") no-repeat;
            // position: absolute;
            right: 25px;
            cursor: pointer;
        }
    }

    .time-line {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: auto;

        .expand-area {
            margin-left: 12px;
            height: 16px;
            width: 16px;
            // background: url("../../../assets/expand_icon.svg") no-repeat;
            background: url("../../../assets/icon-expand.png") no-repeat;
            cursor: pointer;
        }

        .collapse-area {
            margin-left: 12px;
            height: 16px;
            width: 16px;
            background: url("../../../assets/icon-collapse.png") no-repeat;
            cursor: pointer;
        }

        .rotation {
            margin-left: 12px;
            height: 16px;
            width: 16px;
            background: url("../../../assets/screen_rotation.svg") no-repeat;
            cursor: pointer;
        }
    }

    &.expand {
        top: 0;
    }
}

.rd3t-tree-container {
    // height: 88% !important;
    height: calc(100% - 45px) !important; // 92% !important;
    position: absolute;
    z-index: 1;
}

.rd3t-link {
    stroke: #a3a3a3 !important;
}

.translation-bar {
    height: 98px;
    width: 40px;
    background-color: #deedf4;
    position: absolute;
    z-index: 2;

    &.left {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 40%;
        cursor: pointer;

        .lt {
            background: url("../../../assets/ntwk-arrow-right.svg") no-repeat;
            transform: rotate(-180deg);
            height: 48px;
            width: 0; // 48px;

            padding-left: 48px;
            margin-left: -20px;
            background-position: -8px 0 !important;
        }
    }

    &.right {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 40%;
        cursor: pointer;

        .rt {
            background: url("../../../assets/ntwk-arrow-right.svg") no-repeat;
            height: 58px;
            width: 48px;

            padding-right: 48px;
            margin-right: -25px !important;
            background-position: -8px 0 !important;
        }
    }
}

.processing-types {
    display: flex;
    flex-flow: row wrap !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    // position: absolute;
    // bottom: 10%;

    background: transparent;

    .type-content {
        display: flex;
        flex-flow: row wrap !important;
        align-items: center;
        justify-content: flex-start;

        .img {
            width: 20px;
            height: 20px;
            color: #ffffff;
            font-family: "Source Sans Pro";
            font-size: 8px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 8px;
            text-align: center;
            border-radius: 50px;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            &.rdp {
                background-color: #f25e3b;
            }

            &.mdc {
                background-color: #2390bb;
            }

            &.sn {
                background-color: #ffbb43;
            }

            &.fm {
                background-color: #6da06f;
            }

            &.ea {
                background-color: #9151bf;
            }
        }

        .item-name {
            margin-left: 5px;
            color: #3c3c3c;
            font-family: "Source Sans Pro";
            font-size: 13px;
            letter-spacing: 0;
            line-height: 23.8px;
        }

        &:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.legend {
    height: auto;
    width: 100%;
    padding: 10px;
    // margin-bottom: 10px;
    position: absolute;
    bottom: 0;
}

// NETWORK COMPONENT STYLES -----------------------------------------

// @MENTIONS STYLES --------------------------------------------
.mention__suggestions__list {
    max-height: 275px;
    overflow-y: auto;
}
// @MENTIONS STYLES --------------------------------------------
