.in-loader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 108px;

    &-icon {
        background: url("../../../../assets/loaders/dual-ring-loader.svg") no-repeat;
        height: 42px;
        width: 45px;
    }
}
