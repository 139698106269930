.query-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .text-area {
        min-height: 150px;
        resize: vertical;
    }
}
