.file-preview {
    height: 100%;
    width: 100%;
    border: none;

    .txt-view {
        padding: 25px;
    }

    .in-loader {
        height: 85vh !important;
    }

    .content-title {
        font-size: 18px;
        font-weight: bold;
        padding: 8px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #2390bb;
        background-color: #eeeeee;
    }

    .pdf-viewer-wrapper {
        height: 100%; // 85vh;
        width: 100%;
        display: flex;
        flex-direction: column;

        & > div:first-child {
            overflow-x: auto;
            overflow-y: auto;
            height: calc(100% - 55px);
        }

        &.full-height {
            & > div:first-child {
                height: 100%;
            }
        }

        .nav-elem {
            display: flex;
            width: 100%;
            background-color: #dfdbdb;
            color: rgb(78 72 72);
            height: 55px;
            align-items: center;
            justify-content: center;

            #page-num {
                border: 1px solid #dfe2e5;
                border-radius: 2px;
                width: 65px;
                outline: none;
                height: 28px;

                &:focus {
                    border: 1.5px solid #2390bb;
                    outline: none;
                }
            }

            .small {
                height: 28px;
                min-width: 65px;
            }

            .lft-svg {
                height: 10px;
                width: 10px;
                background: url("../../../../assets/left-arrow-svgrepo.svg") no-repeat;
                cursor: pointer;
                margin-right: 10px;
            }

            .rgt-svg {
                height: 10px;
                width: 10px;
                background: url("../../../../assets/left-arrow-svgrepo.svg") no-repeat;
                transform: rotate(180deg);
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }
}

.size-info-container {
    height: 625px;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    & > div:not(:first-child) {
        margin-top: 10px;
    }

    & > div:last-child {
        margin-top: 15px;
    }

    & > div:not(:last-child) {
        color: #444444;
    }
}
