.list-container {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .sub-content {
        display: flex;
        flex-flow: column;
        align-items: center;

        .search-sub-content {
            margin-top: 8px;
            width: 100%;
        }

        .folder-content {
            width: 100%;
            margin-top: 5px;
            max-height: 240px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &.scale-in-ver-top {
            -webkit-animation: scale-in-ver-top 0.25s ease-out both;
            animation: scale-in-ver-top 0.25s ease-out both;
        }

        &.scale-out-ver-top {
            -webkit-animation: scale-out-ver-top 0.25s ease-in both;
            animation: scale-out-ver-top 0.25s ease-in both;
        }
    }
}

@-webkit-keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes scale-out-ver-top {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@keyframes scale-out-ver-top {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}
