.sa-form-elements {
    display: flex;
    flex-flow: row;
    align-items: flex-start; // center;
    justify-content: flex-start;

    width: 100%;

    &:not(:first-child) {
        margin-top: 15px;
    }

    &:first-child {
        margin-top: 15px;
    }

    & > div:not(:first-child) {
        margin-left: 20px;
    }

    &.req {
        color: #444444;
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 15px;

        margin-top: 5px;
        margin-left: -5px;
        span {
            font-size: 18px;
            color: #ec0000;
            margin-top: 3px;
            margin-right: 5px;
        }
    }
}
