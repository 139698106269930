.node-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    outline: none; // position: fixed;

    &.cl {
        padding-top: 10px;
    }
}

.node-arrow {
    height: 52px;
    display: flex;
    flex-flow: row;
    align-items: center;

    .head {
        color: #a3a3a3;
    }
}

.node {
    height: 52px;
    width: 84px;
    border-radius: 8px;
    background-color: #444444;
    color: white;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    position: relative;

    &.svg {
        position: fixed;
        background-color: unset;
        color: white;

        height: 54px;
        width: 56px;
    }

    div.ds-title {
        display: flex;
        flex-flow: column;
        justify-content: center;

        .node-name {
            margin-top: -2px;
        }
    }

    div.node-name {
        // font-family: "Source Sans Pro";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;

        &.version {
            max-width: 52px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &.xsmall {
        width: 52px;
        .node-name {
            width: 100%; // 28px;
        }
    }

    &.small {
        width: 70px;
        .node-name {
            width: 100%; // 45px;
        }
    }

    &.medium {
        width: 84px;
        .node-name {
            width: 100%; // 60px;
        }
    }

    &.large {
        width: 104px;
        .node-name {
            width: 100%; // 80px;
        }
    }

    &.xlarge {
        width: 140px;
        .node-name {
            width: 100%; // 104px;
        }
    }

    &.circle {
        border-radius: 50px;
        height: 34px;
        width: 34px;
        background-color: #ffbb43;
        color: white;
        .node-name {
            width: 34px;
        }

        &.rdp {
            background-color: #f25e3b;
            color: white;
        }

        &.mdc {
            background-color: #2390bb;
            color: white;
        }

        &.sn {
            background-color: #ffbb43;
            color: white;
        }

        &.fm {
            background-color: #6da06f;
            color: white;
        }
        
        &.ea {
            background-color: #9151bf;
            color: white;
        }
    }
}

/* .collection-node {
    &.others {
        foreignObject {
            width: 15% !important;
        }
    }
} */

/* text {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
} */
