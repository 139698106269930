$menu-left-width: 94%;
$menu-right-width: calc(100% - #{$menu-left-width});

.flex-type {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.list-element-container {
    @extend .flex-type;
    justify-content: space-between;

    height: 40px; // 35px;
    width: 100%;
    padding: 0 15px;

    border-bottom: 1px solid #f0f1f2;
    cursor: pointer;

    .part-l {
        @extend .flex-type;
        width: $menu-left-width;
        height: 100%;

        .arrow {
            height: 6px;
            width: 6px;
            background-color: transparent;
            border: 1px solid #444444;
            border-width: 0px 1.55px 1.55px 0px;
            display: inline-flex;

            &.down {
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                margin-top: -4px;
            }

            &.right {
                transform: rotate(315deg);
                -webkit-transform: rotate(315deg);
                -ms-transform: rotate(315deg);
                margin-left: -5px;
            }
        }

        .arrow-content {
            @extend .flex-type;
            justify-content: center;

            height: 100%;
            width: 12px;
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
        }

        .label-content {
            @extend .flex-type;
            width: 100%;

            .folder-icon {
                height: 16px;
                width: 16px;
                background: url("../../../assets/folder-base.svg") no-repeat;
                margin-left: 2px; // 5px; // 8px;
            }

            div.list-name {
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px; // 12px;
                margin-left: 5px;

                // height: 40px; // width: 15vw;
                // display: flex;
                // flex-flow: row; // wrap
                // align-items: center;
                // justify-content: flex-start;

                width: 100%;

                cursor: pointer;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .part-r {
        @extend .flex-type;
        width: $menu-right-width;

        .more-icon {
            height: 16px;
            width: 16px;
            background: url("../../../assets/ellipsis.svg") no-repeat;
            outline: none;
            border: none; // position: relative;
        }
    }

    &:hover {
        background-color: #deedf4; // #eaedf0;
        transition: background-color 0.5s ease;
        .label-content {
            .folder-icon {
                background: url("../../../assets/folder-icon-fill.svg") no-repeat;
            }
        }
        .more-icon {
            background: url("../../../assets/ellipsis-blue.svg") no-repeat;
        }
    }

    &.selected {
        background-color: #deedf4; // #eaedf0;
        .label-content {
            .folder-icon {
                background: url("../../../assets/folder-icon-fill.svg") no-repeat;
            }
        }
        .more-icon {
            background: url("../../../assets/ellipsis-blue.svg") no-repeat;
        }
    }

    &.data-set {
        padding-left: 32px;
    }
    /* &:not(:first-child) {
        border-bottom: 1px solid #f0f1f2;
    } */
}
