.error-modal-container-body-desc {
    margin-top: 25px;
    &:last-child {
        margin-top: 15px;
    }
}

.error-modal-container-body-points {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 15px;

    .point {
        padding-left: 10px;
        span {
            margin-left: 15px;
        }
    }
}
