.hdr-container {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    position: fixed;
    z-index: 4;

    display: flex;
    flex-flow: row nowrap;

    .icon-container {
        background-color: #2390bb;
        font-family: "Source Sans Pro";
        font-weight: bold;
        font-size: 26px;
        letter-spacing: 5px;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: white;

        label {
            cursor: pointer;
        }

        .logo {
            height: 18px;
            width: 116px;
            background: url("../../../assets/omicon-logo.svg");
            cursor: pointer;
        }
    }

    .info-container {
        background-color: #053855;
        color: white;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 5px 1.5%;

        .search {
            color: white;
        }

        .controls {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .notifs {
                background-image: url("../../../assets/notification.svg");
                background-repeat: no-repeat;
                width: 17px;
                height: 20px;
                cursor: pointer;

                .bubble {
                    margin-left: 12px;
                    margin-top: -5px;
                    position: absolute;
                    border-radius: 10px; // 8px;
                    height: 10px; // 12px;
                    width: 8px; // auto;
                    background: rgb(212, 45, 45);
                    font-size: 8px;
                    padding-left: 5px;
                    padding-right: 5px;

                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: center;
                }
            }

            .avatar {
                display: flex;
                flex-flow: row;
                outline: none;

                .img {
                    background: url("../../../assets/user-avatar.svg") no-repeat;
                    height: 30px;
                    width: 30px;
                    border-radius: 50px;
                    cursor: pointer;
                }

                .drop {
                    margin-left: 5px;
                    cursor: pointer;

                    .arrow {
                        height: 8px;
                        width: 8px;
                        background-color: transparent;
                        border: 1px solid white;
                        border-width: 0 1.55px 1.55px 0;
                        display: inline-flex;

                        &.down {
                            transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                        }

                        &.up {
                            margin-top: 15px;
                            transform: rotate(225deg);
                            -webkit-transform: rotate(225deg);
                            -ms-transform: rotate(225deg);
                        }
                    }
                }
            }

            .settings {
                background-image: url("../../../assets/settings.svg");
                background-repeat: no-repeat;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }

            & > div:not(:first-child) {
                margin-left: 15px;
            }
        }

        .part-l {
            display: flex;
            flex-flow: row;
            align-items: center;

            .adv {
                margin-left: 15px;

                color: #ffffff;
                font-family: "Source Sans Pro";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 20px;
                cursor: pointer;
            }
        }
    }
}
