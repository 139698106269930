$loader-dimension: 50px;

.loading-wheel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .text {
        color: #ffffff;
        font-family: "Source Sans Pro";
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        margin-top: 10px;
    }

    img {
        height: $loader-dimension;
        width: $loader-dimension;
    }
}
