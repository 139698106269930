.block-container {
    display: flex;
    flex-direction: column;

    .zoom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;

        .in {
            background: url("../../../../assets/zoom-in.svg") no-repeat;
            height: 24px;
            width: 30px; // 24px;
            cursor: pointer;

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        .out {
            margin-left: 5px;
            background: url("../../../../assets/zoom-out.svg") no-repeat;
            height: 24px;
            width: 30px; // 24px;
            cursor: pointer;

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    .block {
        position: relative;
        overflow-x: auto;
        overflow-y: auto;
    }
}
