// .d3-tree-container {
//     display: flex;
//     flex-direction: column; // row;
//     // align-items: center; // flex-start;

//     // justify-content: center;
//     height: 100%;
//     width: 100%;

//     // padding: 20px;
//     position: relative;
// }

#d3-tree-container-analysis {
    position: relative;
}

.analysis-node-attribute-container {
    position: absolute;
    // top: 40px;
    // right: auto;
    z-index: 4; // 99;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;

    min-height: auto !important; // 333px; // 280px;
    width: 604px;
    border-radius: 4px;
    background-color: #f8f8f8;
    box-shadow: 0 3px 5px 0 #8a8f96;

    .content-1,
    .content-2 {
        width: 100%;
    }

    .close-btn-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between; // flex-end;
        width: 100%;
        height: 30px;
        padding: 15px 10px 5px 0;

        .container-title {
            padding: 20px;
            font-weight: 600;
        }

        .close-btn {
            background: url("../../../../../assets/close-icon.svg") no-repeat;
            height: 27px;
            width: 27px;
            cursor: pointer;
        }
    }

    .detail-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        // padding: 20px 20px 0 20px;
        padding: 20px 20px 20px 20px !important;

        height: 200px;
        overflow-y: auto;
        overflow-x: hidden;

        .title {
            color: #444444;
            font-family: "Source Sans Pro";
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 12px;
        }

        .attributes {
            display: flex;
            flex-flow: column;
            width: 100% !important; // 48%; // 33%; //300px;

            .attribute-body {
                display: flex;
                flex-flow: column;
                margin-top: 15px;

                .item {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    word-break: break-all; // ADDED

                    .key {
                        @extend .title;
                        // max-width: 100px;
                        // word-break: break-all;
                        line-height: 15px;
                    }

                    .value {
                        @extend .title;
                        font-weight: normal;
                        margin-left: 5px;
                        // max-width: 125px;
                        // word-break: break-all;
                        line-height: 15px;

                        &.file {
                            color: #2390bb;
                            margin-left: unset;
                            cursor: pointer;
                        }

                        &.inh-attr {
                            margin-left: unset;
                        }
                    }

                    &.line {
                        // ADDED
                        @extend .title;
                        font-weight: normal;
                        word-break: unset;
                        line-height: 15px;

                        label {
                            color: #2390bb;
                            cursor: pointer;
                            margin-left: 5px;

                            &:nth-of-type(1) {
                                margin-left: 0px !important;
                            }

                            &.normal {
                                cursor: none;
                                color: unset;
                                pointer-events: none;
                            }
                        }
                    }

                    &:not(:first-child) {
                        margin-top: 10px;
                    }
                }
            }

            &:not(:first-child) {
                margin-left: 10px;
            }
        }

        &.lg {
            height: 200px; // auto !important; // 262px;
        }
    }

    .action-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding-top: 15px;

        width: 100%;

        .omi-btn {
            margin-bottom: 15px;
            margin-left: 20px;
        }
    }

    .upload-files {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;

        height: 40px;
        width: 100%;
        border-radius: 0 0 4px 4px;
        background-color: #2390bb;
        color: white;

        cursor: pointer;

        .upload-img {
            background: url("../../../../../assets/upload-icon-blue.svg") no-repeat;
            height: 24px;
            width: 24px;
        }

        .title {
            margin-left: 5px;
        }

        &.disabled {
            opacity: 0.5;
            cursor: none;
            pointer-events: none;
        }
    }

    &.sample-attribute {
        min-height: 333px;
    }
}
