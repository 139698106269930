.docker-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    span {
        font-size: 24px;
        font-family: inherit;
    }
}
