.search-notification-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    font-family: "Source Sans Pro";
    margin-bottom: 105px;

    .title {
        color: #444444;
        font-family: "Source Sans Pro Light";
        font-size: 24px;
        letter-spacing: 0;
        line-height: 32px;
        padding: 20px 20px 30px;
        margin-top: 20px;
    }
    .omi-grid-container {
        .omi-grid-body {
            .row {
                min-height: 50px;
            }
                .unread-notification {
                    background-color: #f8f8f8;
                    box-shadow: 0 1px 0 0 #f0f1f2;
                    font-weight: 600;
                }
        }
            
    }
}
