.pop-over-container {
    height: auto; // 118px;
    min-width: 140px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    position: absolute;
    z-index: 1;
    background-color: #dfe2e5;
    padding-top: 5px;

    // top: 330px; // 25px;
    margin-left: -118px; // -120px;
    // right: 25px;
    // margin-top: 25px;

    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 4px 0 #dfe2e5;

    &-list {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: #ffffff;

        &-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            height: 26px;
            width: 100%;
            color: #3c3c3c;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            padding-left: 5px;
            padding-right: 5px;
            cursor: pointer;

            &-img {
                height: 24px;
                width: 24px;

                &.starred {
                    background: url("../../../assets/pop-over-star-icon.svg") no-repeat;
                }

                &.collaborator {
                    background: url("../../../assets/pop-over-new-user-icon.svg") no-repeat;
                }

                &.download {
                    background: url("../../../assets/pop-over-download-icon.svg") no-repeat;
                }

                &.delete {
                    background: url("../../../assets/pop-over-delete-icon.svg") no-repeat;
                }

                &.profile {
                    background: url("../../../assets/user-icon.svg") no-repeat;
                }

                &.logout {
                    background: url("../../../assets/log-out-icon.svg") no-repeat;
                }

                &.network {
                    background: url("../../../assets/network-icon.svg") no-repeat;
                }

                &.comments {
                    background: url("../../../assets/comments-icon.svg") no-repeat;
                }

                &.open-file {
                    background: url("../../../assets/open-file-icon.svg") no-repeat;
                }

                &.zip {
                    background: url("../../../assets/zip-svgrepo-com.svg") no-repeat;
                }

                &.clear {
                    background: url("../../../assets/cancel-icon.svg");
                    height: 14px;
                    width: 14px;
                    background-position: -2.85px -0.5px;
                    border-radius: 8px;
                    margin-left: 5px;
                }

                &.search-in-list {
                    background: url("../../../assets/search-in-list.png") no-repeat;
                    height: 18px;
                    width: 18px;
                    margin-left: 5px;
                }
            }

            &-label {
                // margin-left: 5px;
                margin-left: 1px;
                margin-right: 4px;
            }

            &:hover {
                background-color: #f8f8f8;
            }

            &:nth-of-type(5) {
                border-top: 1px solid #dfe2e5;
            }

            &.grey {
                // background-color: #f5f4f4;
                pointer-events: none;
                cursor: default;

                .pop-over-container-list-item-img {
                    opacity: 0.25;
                    cursor: default;
                }

                .pop-over-container-list-item-label {
                    // color: #b1aeae;
                    opacity: 0.25;
                    cursor: default;
                }
            }

            &.user-name {
                justify-content: flex-start;
                height: 55px !important;
                padding-right: 10px;
                padding-left: 10px;

                .pop-over-container-list-item-label {
                    margin-right: 0;

                    .user-info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;

                        &-name {
                            font-size: 15px;
                        }

                        &-email {
                            font-size: 12px;
                            color: #2390bb;
                        }
                    }
                }
            }
        }
    }

    &::after {
        content: "";
        position: absolute;
        right: 10px;
        top: -16px;
        /* left: 90px; */
        /* margin-left: 30px; */
        border-width: 8px 4px 8px 4px;
        border-style: solid;
        border-color: transparent transparent #dfe2e5 transparent;
    }

    &.user-options {
        top: 46px;
        // margin-left: -86px;
        right: 45px;

        .pop-over-container-list-item {
            height: 36px;
        }

        &.no-gear {
            right: 12px;
        }
    }

    &.notif-options {
        top: 46px;
        margin-left: unset;
        right: 108px;
        width: 300px;

        .pop-over-container-list {
            max-height: 295px;
            overflow-y: auto;

            &-item {
                height: auto;
                padding-top: 10px;
                padding-bottom: 10px;

                &:nth-of-type(5) {
                    border-top: unset;
                }

                &-label {
                    width: calc(100% - 24px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .notification-body-wrapper {
                width: 100%;
                .loader-wrapper {
                    background-color: rgb(14, 13, 13);
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0.45;
                }

            }
            
        }
        .view-all-notification-link {
            color: #2390bb;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin-left: 15px;
            cursor: pointer;
            font-weight: 600;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &.animate__swing {
            top: 31px;
            right: -6px;
        }
    }

    &.grid {
        margin-top: 25px;
        margin-left: unset;
        right: 14px;

        &.advancedResults {
            right: 50px;

            &.saved-search-queries {
                right: 62px;
            }
        }

        &.sampleAttributes,
        &.fileTypeResults {
            right: -8px;
        }

        &.flow {
            right: 24px;
        }

        &.globalSearchFileTypeResults {
            right: 35px;
        }
        &.userManagement {
            right: 26px;
        }
        &.userManagementForLab {
            right: 20px;
        }
    }

    &.grid_name_download {
        margin-top: 25px;
        margin-left: unset;
        right: -8px;
    }
}
