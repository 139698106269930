.side-content {
    border-right: 1.5px solid #2390bb;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;

    height: 100%; // COMMENT IF NEEDED
}

.hidden {
    display: none;
}
