$loader-dimension: 50px;

.loading-wheel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .text {
        color: #ffffff;
        font-family: "Source Sans Pro";
        font-size: 22px;
        letter-spacing: 0;
        text-align: center;
        margin-top: 10px;
    }

    img {
        height: $loader-dimension;
        width: $loader-dimension;
    }
}

.welcome-note {
    padding: 1% 1.5% 1.5%;
    width: 100%; // 50%;
    color: #ffffff;
    font-family: "Source Sans Pro";
    font-size: 24px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 5px 0 rgba(5, 56, 85, 0.65);
}

.btn-group {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    & > button:not(:first-child) {
        margin-top: 15px;
    }

    .link-container {
        padding-top: 0px !important;
    }

    /* .link-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 65%;
        padding-top: 10px;

        &-for-signup,
        &-for-login {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            width: 100%;

            a {
                color: #093242;
                font-size: 18px;
                font-weight: bolder;
                margin-left: 5px;
                text-decoration: underline;

                &:hover {
                    color: #bccbd1;
                    cursor: pointer;
                }
            }
        }

        &-for-signup {
            padding-top: 10px;
        }

        &-for-login {
            padding-top: 10px;
        }
    } */
}

@media only screen and (max-width: 1296px) {
    .welcome-note {
        width: 600px;
    }
}

@media only screen and (max-width: 685px) {
    .welcome-note {
        width: 475px;
    }
}
