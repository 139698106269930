/* .treemap {
    outline: none;

    g {
        outline: none;

        &:hover {
            opacity: 0.75;
            cursor: pointer;
        }
    }
} */

// #treemap_TreeMap_Squarified_Layout {
//     g {
//         outline: none;

//         &:hover {
//             opacity: 0.75;
//             cursor: pointer;
//         }
//     }
// }

.treemap {
    margin-left: -10px;
    outline: none;
    g {
        outline: none;
        path {
            &:hover {
                opacity: 0.85;
                cursor: pointer;
            }
        }
    }
}

#chart {
    margin-top: -10px;
}

// .apexcharts-toolbar {
//     display: none;
// }

// .apexcharts-menu-icon {
//     display: none;
// }

.tree-map-view {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    max-width: 950px; // width: 850px; // 100%;

    &-cell {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 25px;
        margin-right: 25px;

        &-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .scale {
                background: url("../../../assets/icon-expand.png") no-repeat;
                width: 16px;
                height: 16px;
                cursor: pointer;
                margin-right: 5px;
            }

            .text {
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
                width: 155px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &.shrink {
                    width: 135px;
                }
            }
        }

        &-action {
            color: #2390bb;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 12px;

            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;

            margin-top: -10px;
            cursor: pointer;

            .del {
                background: url("../../../assets/delete-icon.svg") no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
            }
        }
    }

    &.full-view {
        max-width: unset !important;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
        // gap: 25px;
        row-gap: 0px;
        column-gap: 25px;

        .tree-map-view-cell {
            margin-bottom: unset;
            margin-right: unset;
        }
    }
}

.apexcharts-svg {
    width: 200px; // auto;
    height: 200px; // auto;
    // padding-left: 20px;
}

// .resize-triggers {
//     .expand-trigger {
//         div {
//             width: 200px;
//             height: 200px;
//         }
//     }
// }

.apexcharts-treemap-rect {
    width: 200px; // 185px; // 100%;
    height: 200px; // 185px; // 100%;
}

.apexcharts-datalabel {
    visibility: hidden;
}

// .tool-tip {
.ttip {
    width: 125px;
    background-color: black;
    opacity: 0.75;
    color: whitesmoke;
    padding: 5px;
    line-height: 18px;

    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;

    word-break: break-all;

    &::first-letter {
        text-transform: capitalize;
    }
}

.r-window-list {
    width: 100% !important;
    height: 80vh !important;

    .inner-container {
        height: unset !important;
    }
}

.ReactVirtualized__Grid__innerScrollContainer {
    max-width: unset !important;
    width: 100% !important;
    height: unset !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    row-gap: 0px;
    column-gap: 25px;

    max-width: unset !important;
    max-height: unset !important;
    overflow: unset !important;
    position: relative;

    .tree-map-view-cell {
        margin-bottom: unset;
        margin-right: unset;
    }
}
/* .scaleup-container {
    padding: 15px 15px 15px 35px;
    width: 100%;
    height: 100vh;
    height: fit-content;

    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;

    z-index: 3;

    .border-container {
        background: #f7f7f7;
        border: 1.5px solid #dfe2e5;
        border-radius: 5px;
    }

    &-content {
        position: absolute;
        padding: 0 20px;
        top: 0; // 192px; // 175px;
        left: 0;
        right: 0;
        margin-right: auto;
        margin-left: auto;
        width: 100%;

        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    &-button {
        // background: #e4edf1;
        // border-radius: 0 0 5px 5px;

        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 30px;

        .omi-btn {
            margin-right: 10px;
        }
    }

    &.fadeOutUp {
        display: none;
    }

    &.animate__fadeInDown {
        animation-duration: 0.2s;
    }

    &.animate__fadeOutUp {
        animation-duration: 0.2s;
    }
} */
