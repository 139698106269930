$global-search-height: 30px;

@import "../../../../node_modules/@syncfusion/ej2-base/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;
@import "../../../../node_modules/@syncfusion/ej2-querybuilder/styles/fabric.css"; // tailwind.css; // fabric.css; // material.css;

.group {
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 13px;
    width: 100%;

    .label {
        font-family: "Source Sans Pro Light";
        color: #444444;
        font-weight: 300;
        line-height: 13px;
        margin-bottom: 5px;

        display: flex;
        align-items: flex-start;

        span.required-field {
            font-size: 20px;
            color: #ec0000;
            margin-left: 2px;
            margin-top: 2px;
        }

        span.hint {
            background: url("../../../assets/info-circle-solid.svg") no-repeat;
            height: 13px;
            width: 13px;
            margin-left: 4px;
            cursor: pointer;
            position: relative;

            .tooltip {
                position: absolute;
                top: -2.75em;
                left: -8.5em;
                display: flex;
                flex-flow: row;
                padding: 10px;
                background-color: black;
                color: white;
                border-radius: 5px;
                width: max-content;

                visibility: hidden;
            }

            &:hover {
                .tooltip {
                    visibility: visible;
                }
            }
        }
    }

    &.error {
        input.form-input {
            border: 1px solid #f25e3b;
        }
        .text-area {
            border: 1px solid #f25e3b;
        }
        .select {
            border: 1px solid #f25e3b;
        }
        .error-bubble {
            margin-top: 5px;
            font-size: 13px;
            color: indianred;
        }
    }
}

input.form-input {
    background-color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    height: 36px;
    min-width: 250px;
    border: 1px solid #dfe2e5;
    border-radius: 2px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    font-size: 14px;

    /* &:hover {
        color: #2390bb;
        text-decoration: underline;
        cursor: pointer;
    } */

    &:focus {
        border: 1.5px solid #2390bb;
        outline: none;
        /* text-decoration: unset;
        cursor: unset;
        color: unset; */
    }

    &.error {
        border: 1px solid #f25e3b;
    }

    &.g-search {
        height: $global-search-height;
        width: 300px;
        border: none;
        border-radius: 4px 0 0 4px;
        border-right: none;
    }

    &:disabled {
        background-color: #f9f9f9;
        pointer-events: none;
        border: none;
        outline: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &.disabled {
        background-color: #f9f9f9;
        pointer-events: none;
        border: none;
        outline: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.rbt {
    input {
        @extend .form-input;
    }

    &-menu {
        display: none !important;
    }

    &.full-width {
        width: 33.1rem;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.down-arrow {
    height: 16px;
    width: 16px;
    background: url("../../../assets/down-arrow.png") no-repeat;
    cursor: pointer;

    &.disabled {
        opacity: 0.4;
    }
}

.arrow {
    height: 6px;
    width: 6px;
    background-color: transparent;
    border: 1px solid #444444;
    border-width: 0px 1.55px 1.55px 0px;
    display: inline-flex;

    &.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        margin-top: -2px;
    }

    &.up {
        margin-top: 15px;
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
    }

    &.history-down {
        // @extend .arrow.down;

        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        margin-top: -2px;

        border: 1px solid #2390bb;
        border-width: 0 1.55px 1.55px 0;
        height: 7px;
        width: 7px;
    }
}

.search-input {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .g-button {
        height: $global-search-height;
        width: 26px;
        border-radius: 0 4px 4px 0;
        background-color: #2390bb;
        outline: none;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        position: relative;

        cursor: pointer;

        .sr-img {
            height: 16px;
            width: 16px;
            background: url("../../../assets/search-ico.png") no-repeat !important;
            z-index: 5;
            position: absolute;
        }
    }

    div.form-input {
        height: $global-search-height;
        width: 90px;
        border: 1px solid #dfe2e5;
        border-radius: unset;
        border-left: none;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 0 10px;
        color: #444444;
        cursor: pointer;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        &:focus {
            // border: 1px solid #2390bb;
            border-left: none;
            outline: none;
        }

        .options {
            position: absolute;
            width: 90px;
            top: 40.5px;
            margin-left: -11px;
            border: 0.5px solid #8f8f8f;
        }

        label {
            cursor: pointer;
        }

        .g-option {
            height: 26px;
            background-color: white;
            opacity: 2;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding-left: 10px;

            &:hover {
                background-color: #deedf4;
            }
        }

        .g-select {
            position: relative;
        }
    }

    div.line {
        height: $global-search-height;
        background-color: rgba(255, 255, 255, 0.9);
        color: #b3b5b8;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
}

.list-search-container {
    height: 26px;
    padding: 15px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;

    .search-icon {
        height: 16px;
        width: 16px;
        background: url("../../../assets/list-search-icon.svg") no-repeat;
        position: absolute;
        left: 25px;
        cursor: pointer;
    }

    .reset-icon {
        height: 16px;
        width: 16px;
        background: url("../../../assets/reset-512_16x16.png") no-repeat;
        position: absolute;
        right: 25px;
        cursor: pointer;
    }

    .clear-icon {
        // background: url("../../../assets/cancel-icon.svg") no-repeat;
        background: url("../../../assets/reset-512_16x16.png") no-repeat;
        height: 16px;
        width: 16px;
        background-position: -1px -0.5px;
        border-radius: 8px;
        // transform: scaleX(-1);
        position: absolute;
        right: 8px;
        cursor: pointer;
    }

    .form-input {
        height: 24px;
        border: 1px solid #dfe2e5;
        border-radius: 16px 16px;
        color: #444444;
        font-family: "Source Sans Pro";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 20px;
        min-width: unset;
        width: 100%;
        padding-left: 32px;
    }

    &.reset {
        .form-input {
            padding-right: 32px;
        }
    }
}

.multi-select {
    height: 36px; // $global-search-height;
    box-sizing: border-box;
    height: 36px;
    width: 270px;
    border: 1px solid #dfe2e5;
    border-radius: 2px;
    padding: 0 10px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    position: relative;
    background-color: #fff;

    .selected-values {
        display: flex;
        flex-flow: row;
        align-items: center;
        width: 237px;

        overflow-y: hidden;
        overflow-x: auto;

        .select-block {
            height: 20px;
            // min-width: 76px; // width: 76px;
            border-radius: 2px;
            background-color: #deedf4;
            padding: 0 5px;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;

            .selected-name {
                color: #3c3c3c;
                font-family: "Source Sans Pro";
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                max-width: 75px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .cancel {
                background: url("../../../assets/cancel-icon.svg") no-repeat;
                height: 14px;
                width: 14px;
                background-position: -2.85px -0.5px;
                border-radius: 8px;
                margin-right: -2.75px;
                margin-left: 5px;
                cursor: pointer;
            }

            &:not(:first-child) {
                margin-left: 5px;
            }
        }
    }

    .arrow {
        color: #444444;
        cursor: pointer;
    }

    &:focus {
        border: 1px solid #2390bb;
        outline: none;
    }

    &.disabled {
        background-color: #f9f9f9;
        pointer-events: none;
        border: none;
        outline: none;
        font-size:14px;

        .selected-values {
            color: #444444;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;

            display: unset;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .down-arrow {
            background: url("../../../assets/arrow-down-disabled.svg") no-repeat;
        }
    }

    &.full-width {
        background: #ffffff;
        width: 35.75rem;
    }
}

.multi-select-options {
    position: absolute;
    width: 270px;
    top: 37px; // 40px;
    margin-left: -11px;
    border: 1px solid #dfe2e5;
    background: white;
    z-index: 1000;

    display: flex;
    flex-flow: column nowrap;

    &.full-width {
        width: 35.75rem;
    }
}

.all {
    height: 36px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
}

.multi-search {
    height: 36px;
    padding: 5px 0;
    border-top: 0.5px solid #dfe2e5;
    border-bottom: 0.5px solid #dfe2e5;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    .list-search-container {
        width: 100%;
        padding: 0 8px;

        .search-icon {
            left: 18px;
        }
    }
}

.multi-check-space {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    .multi-check {
        input[type="checkbox"] {
            cursor: pointer;
        }
    }
}

.multi-scroll {
    max-height: 108px;
    overflow-x: hidden;
    overflow-y: auto;

    .multi-check-space {
        height: 26px;
    }
}

.select {
    height: 36px; // $global-search-height;
    box-sizing: border-box;
    height: 36px;
    width: 270px;
    border: 1px solid #dfe2e5;
    background-color: #ffffffe6;
    border-radius: 2px;
    padding: 0 10px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    position: relative;
    cursor: pointer;

    &:focus {
        border: 1px solid #2390bb;
        outline: none;
    }

    .options {
        position: absolute;
        width: 270px;
        max-height: 120px;
        top: 37px; // 40px;
        margin-left: -11px;
        border: 1px solid #dfe2e5;
        background: white;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1;

        display: flex;
        flex-flow: column nowrap;

        .select-option {
            height: 30px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 10px;
            opacity: 2;
            cursor: pointer;
            line-height: 18px;
            font-size: 14px;

            .dynamic {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 90%;
            }

            &:hover {
                background-color: #deedf4;
            }
        }
    }

    &.link {
        color: #2390bb;
        font-family: "Source Sans Pro";

        label {
            height: 15px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            font-size: 14px;
        }

        &:focus {
            border: none;
        }
    }

    &.multi-link {
        color: #2390bb;
        font-family: "Source Sans Pro";
        flex-flow: row wrap;
        height: auto;
        min-height: 36px;
        justify-content: flex-start;
        line-height: 20px;
        padding: 8px 5px;

        label {
            margin-left: 8px;
            cursor: pointer;
            font-size: 14px;
        }

        &:focus {
            border: none;
        }
    }

    &.disabled {
        background-color: #f9f9f9;
        pointer-events: none;
        border: none;
        outline: none;

        label {
            color: #444444;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 15px; // 13px;
        }

        .down-arrow {
            background: url("../../../assets/arrow-down-disabled.svg") no-repeat;
        }
    }

    label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        line-height: 14px;
        width: auto;
    }
}

.text-area {
    background-color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    min-height: 70px;
    line-height: 18px;
    width: 100%;
    border: 1px solid #dfe2e5;
    border-radius: 2px;
    padding: 10px;

    font-family: "Source Sans Pro";
    resize: none;
    font-size: 15px; // 13px;

    &:focus {
        border: 1px solid #2390bb;
        outline: none;
    }

    &:disabled {
        background-color: #f9f9f9;
        pointer-events: none;
        border: none;
        outline: none;
    }

    &.disabled {
        background-color: #f9f9f9;
        pointer-events: none;
        border: none;
        outline: none;
        color: #343434;
    }
}
