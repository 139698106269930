.modal-content-container {
    position: fixed;
    top: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 4;

    overflow-x: hidden;
    overflow-y: auto;

    .file-header {
        position: absolute;
        top: 0;
        // height: 40px;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;

        .redirection {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            color: #ffffff;
            font-family: "Source Sans Pro";
            font-size: 13px;
            letter-spacing: 0;
            line-height: 12px;

            .left-arrow {
                height: 16px;
                width: 16px;
                background: url("../../../assets/left-arrow.png") no-repeat;
                margin-right: 10px;
                cursor: pointer;
            }

            .pdf-icon {
                height: 24px;
                width: 24px;
                background: url("../../../assets/pdf-icon.svg") no-repeat;
            }

            .xls-icon {
                height: 24px;
                width: 24px;
                background: url("../../../assets/xls-icon.svg") no-repeat;
            }

            .csv-icon {
                height: 24px;
                width: 24px;
                background: url("../../../assets/xls-icon.svg") no-repeat;
            }

            .r-code-icon {
                height: 19px;
                width: 24px;
                background: url("../../../assets/r-code-re.png") no-repeat;
            }

            .txt-icon {
                height: 16px;
                width: 12.5px;
                zoom: 1.5;
                background: url("../../../assets/files-icon.svg") no-repeat;
                background-position: -5.55px -4.5px;
                border-radius: 0 6px 0 0;
            }
        }

        .comments {
            display: flex;
            flex-direction: row;
            align-items: center;

            .content-title {
                color: #fff;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }

    .modal-container {
        height: 360px;
        width: 450px;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 #f0f1f2;

        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;

        .in-loader {
            width: 100%;
            height: 100vh;
        }

        &-close {
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding-right: 15px;
            margin-top: 15px;
            margin-bottom: 10px;

            span {
                height: 16px;
                width: 16px;
                background: url("../../../assets/button-close-icon.svg") no-repeat;
                cursor: pointer;
            }
        }

        &-header {
            color: #444444;
            font-family: "Source Sans Pro Light";
            font-size: 24px;
            letter-spacing: 0;
            line-height: 32px;
            margin-top: 15px;
        }

        &-body {
            color: #444444;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;

            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: flex-start;
        }

        &-footer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

        &.file-upload {
            height: auto; // 360px;
            width: 450px;

            .modal-container-footer {
                padding-bottom: 20px;
            }
        }

        &.new-sample-attribute,
        &.new-gene-set {
            height: auto; // 540px;
            width: 654px;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 50px;

            .select {
                .options {
                    width: 260px;
                }
            }

            .modal-container-body {
                padding-top: 25px;
            }

            .modal-container-header {
                width: 100%;
                padding: 0 58px;
            }

            .modal-container-footer {
                width: 100%;
            }

            .omi-btn {
                height: 36px;
                width: 200px;
                font-family: "Source Sans Pro";
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 14px;
                text-align: center;
            }
        }

        &.new-gene-set {
            position: absolute;
            top: 25px;
            margin-bottom: 35px;

            .modal-container-footer {
                .omi-btn {
                    width: 133px;
                }
            }
        }

        &.sample-attribute-modal {
            height: auto;
            width: 700px;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 50px;

            .modal-container-body {
                padding-top: 25px;

                .tree-map-view {
                    max-width: unset;
                    width: 700px;
                    padding-left: 60px; // 75px;

                    &-cell {
                        margin-right: 40px;

                        &-action {
                            display: none;
                        }
                    }
                }
            }

            .modal-container-header {
                width: 100%;
                padding: 0 58px;
            }

            .modal-container-footer {
                display: none; // width: 100%;
            }

            .omi-btn {
                display: none;
            }
        }

        &.sample-attribute-modal {
            position: absolute;
            top: 25px;
            margin-bottom: 35px;
        }

        &.file-preview {
            position: absolute;
            top: 75px;
            margin-bottom: 35px;
            width: 90%;
            height: 82%; // 85%;

            overflow-y: auto; // UNCOMMENT TO REVERT

            align-items: flex-start;

            &.csv {
                height: auto;
            }

            &.pdf {
                align-items: center;
                overflow-y: unset !important;
            }

            .jexcel_container {
                width: 100%;

                .jexcel_content {
                    max-height: 85vh !important; // 625px !important;
                    max-width: 100% !important;

                    thead.resizable>tr>td {
                        font-weight: 500 !important;
                        text-overflow: ellipsis !important;
                    }

                    tbody.resizable>tr>td {
                        vertical-align: top !important;

                        &:focus {
                            pointer-events: none;
                            border: 1px solid green;
                        }
                    }
                }

                .jexcel {
                    width: 100%;

                    &_row {
                        width: 100px;
                    }
                }

                .sheet-cell {
                    text-align: left !important;
                    word-break: break-word;
                    white-space: pre-wrap !important;
                    line-height: 18px !important;
                    // text-overflow: ellipsis !important;
                }
            }

            &::-webkit-scrollbar {
                width: 10px;
                height: 2px;
            }

            &::-webkit-scrollbar-track {
                background: rgb(231, 231, 231);
                border-radius: 8px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: #a3a3a3;
            }
        }

        &.dc-upload {
            height: auto; // 540px;
            width: 500px;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 50px;
            position: absolute;
            top: 25px;
            margin-bottom: 35px;

            .modal-container-footer {
                .omi-btn {
                    width: 133px;
                }
            }
        }

        &.new-user {
            position: absolute;
            top: 25px;
            margin-bottom: 35px;
            height: auto;
            width: 500px;
            padding-bottom: 50px;
            border-radius: 2px;

            .modal-container-body {
                padding-top: 25px;
            }

            .omi-btn {
                margin-top: 20px;
            }
        }

        &.save-search-query {
            width: 35%;
            height: auto;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 35px;

            .modal-container-body {
                width: 100%;
                padding: 25px 58px 0;
            }

            .modal-container-header {
                width: 100%;
                padding: 0 58px;
                margin-top: 5px;
            }

            .modal-container-footer {
                width: 100%;
                margin-top: 35px;

                .omi-btn {
                    &:nth-of-type(2) {
                        margin-left: 20px;
                    }
                }
            }
        }

        &.single-sample-block {
            height: auto;
            width: auto;
            justify-content: center;
            align-items: center;
            padding-bottom: 50px;
            padding-left: 3.5%;
            padding-right: 2.75%;

            border-radius: 10px;

            .modal-container-close {
                margin-top: 10px;
                margin-bottom: 5px;
            }

            .modal-container-body {
                padding-top: 25px;
            }

            .modal-container-header {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                font-weight: 600;
                margin-top: unset;
            }

            .modal-container-footer {
                display: none;
            }

            .omi-btn {
                display: none;
            }
        }

        &.single-sample-block {
            position: absolute;
            top: 25px;
            margin-bottom: 35px;
        }

        &.report-bug {
            height: auto; // 75%;
            width: 45%;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 35px;
            border-radius: 4px;

            .modal-container-footer {
                font-weight: bold;
            }

            .modal-container-body {
                padding: 32px; // padding-bottom: 0;
                // height: calc(100% - 20px);
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
            }

            .modal-container-footer {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        &.report-bug {
            position: absolute;
            top: 25px;
            margin-bottom: 35px;
        }

        &.tos {
            height: auto; // 75%;
            width: 45%;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 35px;
            border-radius: 4px;
            position: absolute;
            top: 25px;
            margin-bottom: 35px;
        }

        &.scale-in-center {
            -webkit-animation: scale-in-center 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: scale-in-center 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }

        &.scale-out-center {
            -webkit-animation: scale-out-center 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
            animation: scale-out-center 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        }

        &.version-update {
            height: 225px;
            width: 355px;

            .message-modal-container {
                padding-bottom: 15px;
            }

            .modal-container-footer {
                margin-top: 10px;
            }
        }
    }
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
}

@keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
}