@font-face {
  font-family: Source Sans Pro;
  src: url("./assets/fonts/SourceSansPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: Source Sans Pro Light;
  src: url("./assets/fonts/SourceSansPro-Light.otf") format("opentype");
}

@font-face {
  font-family: Source Sans Pro Semibold;
  src: url("./assets/fonts/SourceSansPro-Semibold.otf") format("opentype");
}

* {
  box-sizing: border-box;
  font-family: "Source Sans Pro";
  scrollbar-width: thin;
  /* Works on Firefox */
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: whitesmoke;
}

*::-webkit-scrollbar-thumb {
  // border: 3px solid orange;
  // background-color: #dfe2e5;
  border-radius: 5px;
  background-color: #aeb3b8;
}

$side-width: 20%; // 260px;

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  margin-top: -2px;
}

a {
  text-decoration: none;
  color: black;
}

.app-container {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .rb-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.pre-auth-link {
  margin-top: 15px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 12px;
  cursor: pointer;

  display: flex;
  flex-flow: row;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.side-width {
  width: $side-width;
}

.full-width {
  width: calc(100% - #{$side-width});
}

input[type="checkbox"] {
  &:checked {
    background: #fff;
    color: #333;
    content: "\2713";
    text-align: center;
  }
}

.overlay-container {
  position: fixed;
  top: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(14, 13, 13);
  height: 100%;
  width: 100%;
  opacity: 0.75;
  z-index: 4; // 6; // 2;

  &.loading {
    z-index: 6; // 2;
  }

  &.file-preview {
    opacity: 0.9;
  }
}

.app-arrow {
  height: 6px;
  width: 6px;
  background-color: transparent;
  border: 1px solid #444444;
  border-width: 0px 1.55px 1.55px 0px;
  display: inline-flex;

  &.down {
    @extend .arrow-down;
  }

  &.up {
    margin-top: 15px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
  }

  &.right {
    transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    margin-top: 2px;
  }

  &.version {
    @extend .arrow-down;
    border: 1px solid #2390bb;
    border-width: 0 1.55px 1.55px 0;
    height: 7px;
    width: 7px;
  }
}

.notification__title, .notification__message {
  font-size: 15px !important;
}

/* .ej2-tool-tip {
  background-color: rgb(44, 44, 44) !important;
  border: 1px solid rgb(44, 44, 44) !important;
  opacity: 7 !important;
  color: #dfe2e5 !important;
} */