@mixin image($width, $height, $image-name, $pointer: pointer) {
    height: $height;
    width: $width;
    background: url("../../assets/#{$image-name}") no-repeat;
    cursor: $pointer;
}

@mixin flexDisplay($direction, $align, $justify, $wrap: unset) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
    flex-wrap: $wrap;
}

@mixin flexAlign($direction, $align) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
}


@mixin flexJustify($direction, $justify) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
}

@mixin fontModel($size, $weight: unset, $line: 12px) {
    font-family: "Source Sans Pro";
    font-size: $size;
    font-weight: $weight;
    line-height: $line;
}