.grading {
    background: linear-gradient(135deg, #003269 0%, #2390bb 100%);
    opacity: 0.75;
}

.pre-auth-background {
    background-image: linear-gradient(to right, rgba(0, 50, 105, 0.92), rgba(34, 144, 187, 0.25)),
        url("../../../assets/pre-auth-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .title {
        font-family: sans-serif;
        font-size: 3.75em; // 62px;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 5px;
    }

    .input-container {
        // height: 50%; // 363px;
        min-height: 390px;
        height: auto;
        padding: 2.5% 0;
        width: 28%; // 32%; // 400.67px;
        // opacity: 0.45;
        background-color: #02263c75; // #02263c;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        // position: relative;

        &.register {
            width: 45%;
        }

        &.user-verification {
            min-height: 125px !important;

            .within-container {
                font-size: 22px;

                &.error {
                    color: #ff5454;
                }
            }
        }
        
        &.reset-password {
            min-height: 275px !important;

            .within-container {
                font-size: 22px;

                &.error {
                    color: #ff5454;
                }
            }
        }
    }

    .normal-container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 50%;
    }

    .within-container {
        // position: absolute;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    form {
        .group:not(:first-child) {
            margin-top: 15px;
        }
    }

    .link-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 10px;

        &-for-signup,
        &-for-login {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            width: 100%;

            a,
            span {
                color: #000000;
                font-size: 18px;
                // font-weight: bolder;
                margin-left: 5px;
                text-decoration: underline;

                &:hover {
                    color: #ffffff;
                    cursor: pointer;
                }
            }
        }

        &-for-signup {
            padding-top: 10px;
        }

        &-for-login {
            padding-top: 10px;
        }

        .link-for-signup {
            padding-top: 5px;
        }
    }
}

@media only screen and (max-width: 1296px) {
    .pre-auth-background {
        .input-container {
            width: 400.67px;
        }
    }
}

@media only screen and (max-height: 800px) {
    .pre-auth-background {
        .input-container {
            min-height: 390px;
        }
    }
}
