.right-side-panel {
    position: absolute;
    top: 0;
    right: -500px; /* Initially, it is positioned off the screen */
    width: 500px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    padding: 20px;
    overflow-y: auto;
    display: block;
    transition: right 0.5s ease-in-out; /* Adds transition for smooth sliding */

    /* When the panel is open, it moves to the right edge */
    &.open {
        right: 0;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
        z-index: 20; /* Ensure it sits above the side panel */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Loading spinner or text */
    .loading-spinner {
        color: white;
        font-size: 20px;
        font-weight: bold;
    }

    .header-container {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-right: 15px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .close-btn {
        height: 16px;
        width: 16px;
        background: url('../../../assets/button-close-icon.svg') no-repeat;
        cursor: pointer;
    }
}