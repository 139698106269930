.main-content {
    display: flex;
    flex-flow: column;
    // justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    &.full-screen {
        width: 100%;
    }
}
