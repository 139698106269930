.omi-btn {
    box-sizing: border-box;
    height: 38px;
    min-width: 135px;
    padding: 0 1%;
    border: 1px solid #2390bb;
    border-radius: 18px;

    font-family: "Source Sans Pro";
    text-align: center;
    cursor: pointer;
    color: #444444;

    &.wide {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        width: 175px;
    }

    &.large {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
    }

    &.small {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
    }

    &.upload {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        height: 28px;
        border-radius: 13.5px;
        background-color: #2390bb;

        font-family: "Source Sans Pro Light";

        .cloud {
            height: 24px;
            width: 24px;
            background: url("../../../assets/upload-icon.svg") no-repeat;
            margin-right: 5px;
        }

        .add {
            height: 16px;
            width: 16px;
            background: url("../../../assets/add-icon.svg") no-repeat;
        }
    }

    &.file-uploads {
        color: #444444;
        background-color: white;

        &:hover {
            // @extend .omi-btn.inverted;
            background-color: #2390bb;
            color: #ffffff;
        }
    }

    &.inverted {
        background-color: #2390bb;
        color: #ffffff;
    }

    &:hover {
        // @extend .omi-btn.inverted;
        background-color: #2390bb;
        color: #ffffff;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: #2390bb;
        opacity: 0.5;
        cursor: none;
        pointer-events: none;
    }
}
