.perm-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 25px;

    &-title {
        font-size: 22px;
    }

    &-desc {
        font-style: italic;
    }
}
