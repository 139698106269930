.tos-title {
    font-weight: 600 !important;
    padding-left: 50px !important;
    font-size: 28px !important;
}

.tos-container {
    padding-left: 50px;
    padding-right: 50px;
    line-height: 1.5;

    a {
        text-decoration: underline;
        color: blue;
    }
}



.tos-sub-title {
    padding-left: 0 !important;
    font-size: 18px !important;
}