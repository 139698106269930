.dc-wrapper {
    display: flex;
    // flex-flow: row;
    flex-flow: column;
    // align-items: flex-start; // center;
    align-items: center;
    // justify-content: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    padding-top: 15px;

    .upload-process {
        width: 100%;
    }
}

.dc-upload-container-block-drop {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    // margin-top: 15px;

    .block-drop-label {
        font-family: "Source Sans Pro Light";
        color: #444444;
        font-weight: 300;
        line-height: 13px;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    &-container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        height: 100px;
        width: 450px; // 100%; // 341px;
        border: 1px dashed #dfe2e5;

        padding-top: 15px;

        &-img {
            height: 32px;
            width: 41px;
            background: url("../../../../../../assets/drag-icon.svg") no-repeat;
        }

        &-desc {
            color: #444444;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            // margin-top: 10px;

            span {
                color: #2390bb;
                cursor: pointer;
            }
        }

        &.files {
            height: unset;
            padding: 10px;
            position: relative;

            ul {
                padding-left: 10px;
            }

            li {
                list-style: square;
                color: #2390bb;
                font-family: "Source Sans Pro";
                font-size: 14px;
                span {
                    color: #444444;
                }
            }

            .selected-file {
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 18px;
                letter-spacing: 0.5px;
                line-height: 24px;
                margin-top: 10px;
            }
        }
    }

    .upload-btn {
        margin-top: 20px;
    }
}
