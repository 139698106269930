.wide {
    background-color: #f34d4d;
    min-width: 100px !important;
    width: 100px !important;
    height: 35px;
    color: white;
    font-size: 12px !important;
    letter-spacing: 2px !important;
    border-radius: 50px !important;
    font-weight: bolder;
    border: 2px solid;

    &:hover {
        background-color: #f31b1b;
    }
}

.rbm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .rbm-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: inherit;

        .group {
            width: unset;
            align-self: flex-start;
        }
    }

    &-block-drop {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        // margin-top: 15px;

        .block-drop-label {
            font-family: "Source Sans Pro Light";
            color: #444444;
            font-weight: 300;
            line-height: 13px;
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;
            width: 100%;

            .req {
                font-size: 20px;
                color: #ec0000;
                margin-left: 2px;
                margin-top: 2px;
            }
        }

        &-container {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            box-sizing: border-box;
            height: 100px;
            width: 100%; // 341px;
            border: 1px dashed #dfe2e5;

            padding-top: 15px;

            &-img {
                height: 32px;
                width: 41px;
                background: url("../../../assets/drag-icon.svg") no-repeat;
            }

            &-desc {
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                // margin-top: 10px;

                span {
                    color: #2390bb;
                    cursor: pointer;
                }
            }

            &.files {
                height: unset;
                padding: 10px;

                ul {
                    padding: 0 10px;
                    width: 100%;
                }

                li {
                    color: #2390bb;
                    font-size: 10px;

                    display: flex;
                    flex-flow: row;
                    align-items: flex-start;
                    justify-content: space-between;

                    span.title {
                        font-family: "Source Sans Pro";
                        font-size: 14px;
                        color: #444444;
                        width: 80%;
                        word-break: break-all;
                        margin-left: -20px;
                        margin-top: -2px;
                    }

                    span.delete-item {
                        width: 20%;
                        background: url("../../../assets/cancel-icon.svg") no-repeat;
                        border-radius: 20px;
                        height: 13px;
                        background-position: -3px -1px;
                        width: 13px; // margin-top: -2px;
                        cursor: pointer;
                        margin-top: -1px;
                    }

                    &:not(:first-child) {
                        margin-top: 8px;
                    }
                }

                .selected-file {
                    color: #444444;
                    font-family: "Source Sans Pro";
                    font-size: 18px;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                    margin-top: 10px;
                }
            }
        }

        .upload-btn {
            margin-top: 20px;
        }

        .upload-process {
            font-family: "Source Sans Pro";
            font-size: 14px;
            color: #444444;
            margin-top: 25px;
            width: 100%;

            span {
                color: #2390bb;
            }
        }
    }
}

.report-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 25px 15px 25px 0;
        font-size: 18px;
        width: inherit;

        &-name {
            font-weight: bold;
        }

        &-version {
            margin-left: 15px;
        }
    }

    &-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: inherit;

        .group {
            &.comment {
                grid-column: 1 / 3;

                .text-area {
                    height: 165px;
                }
            }
        }
    }
}

.rb-btn-link,
.pre-auth-feedback {
    color: #ffffff;
    text-decoration: underline;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    font-style: italic;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
        font-weight: bold;
    }
}

.pre-auth-feedback {
    font-size: 16px;
    font-weight: bold;
}

.about-help-feedback-wrapper {
    display: flex;
}